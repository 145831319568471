import Swiper, { Navigation, Pagination } from 'swiper';

const sliders = document.querySelectorAll('.business-areas__carousel');

Swiper.use([Navigation, Pagination]);

for (let i = 0; i < sliders.length; i++) {
    sliders[i].classList.add('business-areas__carousel-' + i);
    $(sliders[i])
        .parent()
        .parent()
        .parent()
        .find('.swiper-pagination')
        .addClass(`swiper-pagination-business-${i}`);
    $(sliders[i])
        .parent()
        .parent()
        .parent()
        .find('.business-areas__arrow-desktop--next')
        .addClass(`business-areas__arrow-desktop--next-${i}`);
    $(sliders[i])
        .parent()
        .parent()
        .parent()
        .find('.business-areas__arrow-desktop--prev')
        .addClass(`business-areas__arrow-desktop--prev-${i}`);

    const mobileCarusel = new Swiper('.business-areas__carousel-' + i, {
        slideActiveClass: 'is-active',
        loop: true,
        slideToClickedSlide: !Array.from(sliders[i].classList).includes('withLinks'),
        breakpoints: {
            0: {
                slidesPerView: 1.2,
                spaceBetween: 16,
            },
            576: {
                spaceBetween: 16,
                slidesPerView: 1.5,
            },
            1200: {
                slidesPerView: 2.1,
                spaceBetween: 24,
            },
            2000: {
                spaceBetween: 24,
                slidesPerView: 2.1,
            },
        },
        navigation: {
            nextEl: `.business-areas__arrow-desktop--next-${i}`,
            prevEl: `.business-areas__arrow-desktop--prev-${i}`,
        },
        pagination: {
            el: `.swiper-pagination-business-${i}`,
            type: 'bullets',
            bulletClass: 'bottom-pagination__bullet',
            bulletActiveClass: 'active',
        },
    });

    mobileCarusel.on('slideChange', e => {
        const activeIndex = e.realIndex + 1;
        const currentSlideDomElement = $(sliders[i])
            .parent()
            .parent()
            .parent()
            .find('.business-areas__current-slide');
        currentSlideDomElement.text(activeIndex);
    });
}
